import React from "react";
import { useParams } from "react-router";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import { useGetBciChartQuery } from "../../queries/bciApiSlice";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { BridgeConditionIndexGraph } from "./BridgeComponentGraph";

export interface BridgeConditionIndexGraphComponentProps {
    chartType: string;
    scoreType: string;
    bciConfigName?: string;
    title: string;
}

const BridgeConditionIndexGraphComponent: React.FC<
    BridgeConditionIndexGraphComponentProps
> = (props) => {
    const params = useParams<{ orgShortName: string }>();
    const { chartType, scoreType, title, bciConfigName } = props;

    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );

    const { data: bciAvgDefectCategory, isLoading } = useGetBciChartQuery({
        organisationId: organisation?.id,
        params: { chartType, scoreType, bciConfigName },
    });

    if (isLoading) {
        return <CoveringLoadingPanel />;
    } else {
        return (
            <BridgeConditionIndexGraph
                chart={bciAvgDefectCategory}
                title={title}
            />
        );
    }
};

export default BridgeConditionIndexGraphComponent;
