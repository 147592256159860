import { Card } from "react-bootstrap";
import React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
} from "@progress/kendo-react-charts";
import { useParams } from "react-router";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useGetBciChartQuery } from "../../queries/bciApiSlice";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";

const ComponentConditionIndexRatiosbyDefectCategory: React.FC = () => {
    const params = useParams<{ orgShortName: string }>();
    const id = crypto.randomUUID();
    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );

    const { data: indexRatios, isLoading } = useGetBciChartQuery({
        organisationId: organisation?.id,
        params: { chartType: "indexRatio", scoreType: "avg" },
    });

    if (isLoading) {
        return <CoveringLoadingPanel />;
    }

    return (
        <div className="m-2">
            <Card className="card d-flex flex-column">
                <Card.Header>
                    <p
                        className="h4"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                        Component Condition Index Ratios by Defect Category
                    </p>
                </Card.Header>
                <Card.Body
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                    }}
                >
                    <Chart key={id}>
                        <ChartLegend position="top" orientation="horizontal" />
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                categories={indexRatios.categories}
                                labels={{ rotation: 45 }}
                            />
                        </ChartCategoryAxis>
                        <ChartSeries>
                            {indexRatios.series.map((item, idx) => (
                                <ChartSeriesItem
                                    key={id + "-" + idx}
                                    type="column"
                                    tooltip={{ visible: true }}
                                    data={item.values}
                                    name={item.name}
                                />
                            ))}
                        </ChartSeries>
                    </Chart>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ComponentConditionIndexRatiosbyDefectCategory;
