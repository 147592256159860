import cx from "classnames";
import * as React from "react";
import { Nav } from "react-bootstrap";
import {
    FaCalendar,
    FaClipboard,
    FaFile,
    FaFolderOpen,
    FaQuestionCircle,
    FaHome,
    FaSearch,
    FaUserCog,
    FaUserPlus,
    FaUsers,
} from "react-icons/fa";
import { useLocation } from "react-router";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import CefaSideMenu from "../../../modules/cefa/common/components/CefaSideMenu";
import MyWorkMenuItem from "../../../modules/cefa/examinations/components/MyWorkMenuItem";
import HseqSideMenu from "../../../modules/ssow/common/components/HseqSideMenu";
import { Organisation } from "../../../modules/organisation/domain/types";
import ProcessesMenu from "../../../modules/process/components/ProcessesMenu";
import AssetsMenu from "../../../modules/asset/components/AssetsMenu";
import SchedulerMenu from "../../../modules/scheduler/components/SchedulerMenu";
import { AppRoutes } from "../../../router/AppRoutes";
import { useNavigation } from "../../../router/useNavigate";
import useViewPermissions from "../../hooks/useViewPermissions";
import NavLink from "../NavLink";
import NavLinkWithMenu from "./NavLinkWithMenu";
import "./SideMenu.scss";
import SideMenuFooter from "./SideMenuFooter";
import SbimConstants from "../../../modules/sbim/constants/SbimConstants";
import CefaConstants from "../../../modules/cefa/constants/CefaConstants";
import SsowConstants from "../../../modules/ssow/Constants/SsowConstants";
import DocumentationNavLink from "../documentation/DocumentationNavLink";
import useIsClient from "../../hooks/useIsClient";
import useOrganisationLogo from "../../../modules/organisation/hooks/useOrganisationLogo";

interface SideMenuProps {
    className?: string;
    isExpanded: boolean;
    toggleExpansion: () => void;
    organisation: Organisation;
}

const SideMenu: React.FC<SideMenuProps> = ({
    className,
    toggleExpansion,
    isExpanded,
    organisation,
}) => {
    const location = useLocation();
    const { getOrgLink: getLink } = useNavigation();

    const getActiveKey = (pathName: string): string => {
        const topLevelMenuArrayLocation = 2;
        const pathArray = pathName.split("/");
        if (pathArray.length > 2) {
            return pathArray[topLevelMenuArrayLocation];
        } else {
            return "home";
        }
    };

    const {
        canViewProcesses,
        canViewHelpSection,
        canViewUsers,
        canViewGroups,
        canViewFiles,
        canViewSearch,
        canInviteUsers,
        canViewReports,
        canViewExaminationsSchedule,
    } = useViewPermissions(organisation.id);

    const isSBIM = useIsClient(SbimConstants.ClientName);
    const isCefa = useIsClient(CefaConstants.ClientName);
    const isSsow = useIsClient(SsowConstants.ClientName);

    const activeKey = getActiveKey(location.pathname);

    const canViewProcessReports = isSBIM && canViewReports && canViewProcesses;
    const reportSubLinks = canViewProcessReports
        ? [
              {
                  label: "Site Work Complete",
                  to: `/${organisation.shortName}${OrganisationConstants.siteWorks}`,
                  icon: FaClipboard,
              },
              {
                  label: "Inspections Complete",
                  to: `/${organisation.shortName}${OrganisationConstants.inspections}`,
                  icon: FaClipboard,
              },
              {
                  label: "Defect Report",
                  to: `/${organisation.shortName}${OrganisationConstants.defectReport}`,
                  icon: FaClipboard,
              },
              {
                  label: "Bridge Condition Indices",
                  to: `/${organisation.shortName}${OrganisationConstants.bridgeConditionIndices}`,
                  icon: FaClipboard,
              },
          ]
        : [];

    const fileSubLinks = canViewSearch
        ? [
              {
                  label: "Search",
                  to: `/${organisation.shortName}/${OrganisationConstants.fileSearch}`,
                  icon: FaSearch,
              },
          ]
        : [];

    const usersSubLinks = [
        canViewGroups && {
            label: "Groups",
            to: `/${organisation.shortName}/${OrganisationConstants.userGroups}`,
            icon: FaUsers,
        },
        canInviteUsers && {
            label: "Invitations",
            to: `/${organisation.shortName}/${OrganisationConstants.invitations}`,
            icon: FaUserPlus,
        },
    ].filter((x) => !!x);

    const orgLogo = useOrganisationLogo(organisation.shortName);

    return (
        <nav
            className={cx(
                className,
                { "bg-light": !isSBIM },
                "d-none d-md-block sidebar",
            )}
        >
            <div
                className="sidebar-sticky d-flex flex-column"
                id="side-menu-background"
            >
                <Nav
                    className="nav flex-column flex-grow-1"
                    activeKey={activeKey}
                >
                    {orgLogo && isSBIM && (
                        <div className="image-placeholder">
                            <img
                                src={orgLogo}
                                alt={organisation.name}
                                className={"menu-image"}
                                style={{ height: "6.5rem" }}
                            />
                        </div>
                    )}
                    {isSBIM && (
                        <NavLink
                            to={`/${organisation.shortName}${OrganisationConstants.Overview}`}
                            label="Overview"
                            icon={FaHome}
                            eventKey="organisation-overview"
                        />
                    )}
                    <MyWorkMenuItem organisationId={organisation.id} />
                    <ProcessesMenu organisation={organisation} />
                    <AssetsMenu organisation={organisation} />
                    <SchedulerMenu organisation={organisation} />

                    {isCefa && canViewExaminationsSchedule && (
                        <NavLink
                            to={getLink(AppRoutes.SchedulerExaminations)}
                            label="Schedule Examinations"
                            icon={FaCalendar}
                            eventKey="scheduler-examinations"
                        />
                    )}

                    {isCefa && (
                        <NavLink
                            to={getLink(AppRoutes.SearchExaminations)}
                            label="Examinations Search"
                            icon={FaSearch}
                            eventKey="search-examinations"
                        />
                    )}

                    {canViewProcessReports && (
                        <NavLinkWithMenu
                            to={`/${organisation.shortName}${OrganisationConstants.Report}`}
                            label="Reports"
                            icon={FaFile}
                            eventKey="report"
                            subMenuRoute={`/${organisation.shortName}${OrganisationConstants.Report}`}
                            subLinks={reportSubLinks}
                        />
                    )}
                    {canViewFiles && (
                        <NavLinkWithMenu
                            to={`/${organisation.shortName}/${OrganisationConstants.folder}`}
                            label={
                                organisation?.features?.files?.sideMenuName ??
                                "Files"
                            }
                            icon={FaFolderOpen}
                            eventKey={OrganisationConstants.files}
                            subLinks={fileSubLinks}
                            subMenuRoute={`/${organisation.shortName}/${OrganisationConstants.files}`}
                        />
                    )}
                    {!isSBIM && canViewHelpSection && (
                        <NavLink
                            to={`/${organisation.shortName}/${OrganisationConstants.help}`}
                            label="Help"
                            icon={FaQuestionCircle}
                            eventKey={OrganisationConstants.help}
                        />
                    )}
                    {canViewUsers && (
                        <NavLinkWithMenu
                            to={`/${organisation.shortName}/${OrganisationConstants.users}`}
                            label="Users"
                            icon={FaUserCog}
                            eventKey={OrganisationConstants.users}
                            subLinks={usersSubLinks}
                            subMenuRoute={`/${organisation.shortName}/${OrganisationConstants.users}`}
                        />
                    )}
                    {isCefa && <CefaSideMenu />}
                    {isSsow && canViewReports && <HseqSideMenu />}
                    <DocumentationNavLink />
                </Nav>
                <SideMenuFooter
                    toggleExpansion={toggleExpansion}
                    isExpanded={isExpanded}
                    organisation={organisation}
                />
            </div>
        </nav>
    );
};

export default SideMenu;
