import { Card, Col, Row } from "react-bootstrap";
import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

export interface Props {
    bciAvgScore: number;
    bciCritScore: number;
}

const BciComponent: React.FC<Props> = (props) => {
    const { bciAvgScore, bciCritScore } = props;
    return (
        <Card className="card d-flex flex-column">
            <Card.Header>
                <p
                    className="h4"
                    style={{ textAlign: "center", fontWeight: "bold" }}
                >
                    Bridge Condition Index{" "}
                    <small style={{ fontSize: "0.5em" }}>
                        (BCI scores are valid only when all elements of the
                        bridge or structure have been inspected and logged in
                        the system)
                    </small>
                </p>
            </Card.Header>
            <Card.Body
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Row>
                    <Col>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            BCI Average: {bciAvgScore}
                        </p>
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            value={bciAvgScore}
                            needleColor="orange"
                            startColor="red"
                            segments={10}
                            endColor="green"
                        />
                    </Col>
                    <Col>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            BCI Critical: {bciCritScore}
                        </p>
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            value={bciCritScore}
                            needleColor="orange"
                            startColor="red"
                            segments={10}
                            endColor="green"
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default BciComponent;
