import React, { useMemo } from "react";
import { useParams } from "react-router";
import ComponentConditionIndexRatiosbyDefectCategory from "../components/bci-components/ComponentConditionIndexRatiosbyDefectCategory";
import BridgeConditionIndexGraphComponent from "../components/bci-components/BridgeConditionIndexGraphComponent";

type SubsetConfig = {
    bciConfigName: string;
    title: string;
};

const subsetConfigs: Record<string, SubsetConfig[]> = {
    pow: [
        {
            bciConfigName: "Avon Subset",
            title: "Bridge Condition Index (Average by component group) For Avon Subset",
        },
        {
            bciConfigName: "Gwent Subset",
            title: "Bridge Condition Index (Average by component group) For Gwent Subset",
        },
        {
            bciConfigName: "Shoots Subset",
            title: "Bridge Condition Index (Average by component group) For Shoots Subset",
        },
        {
            bciConfigName: "Avon Subset",
            title: "Bridge Condition Index (Critical by component group) For Avon Subset",
        },
        {
            bciConfigName: "Gwent Subset",
            title: "Bridge Condition Index (Critical by component group) For Gwent Subset",
        },
        {
            bciConfigName: "Shoots Subset",
            title: "Bridge Condition Index (Critical by component group) For Shoots Subset",
        },
    ],
    severn: [
        {
            bciConfigName: "Aust Subset",
            title: "Bridge Condition Index (Average by component group) For Aust Subset",
        },
        {
            bciConfigName: "Beachley Subset",
            title: "Bridge Condition Index (Average by component group) For Beachley Subset",
        },
        {
            bciConfigName: "Severn Subset",
            title: "Bridge Condition Index (Average by component group) For Severn Subset",
        },
        {
            bciConfigName: "Toll Subset",
            title: "Bridge Condition Index (Average by component group) For Toll Subset",
        },
        {
            bciConfigName: "Wye Subset",
            title: "Bridge Condition Index (Average by component group) For Wye Subset",
        },
        {
            bciConfigName: "Aust Subset",
            title: "Bridge Condition Index (Critical by component group) For Aust Subset",
        },
        {
            bciConfigName: "Beachley Subset",
            title: "Bridge Condition Index (Critical by component group) For Beachley Subset",
        },
        {
            bciConfigName: "Severn Subset",
            title: "Bridge Condition Index (Critical by component group) For Severn Subset",
        },
        {
            bciConfigName: "Toll Subset",
            title: "Bridge Condition Index (Critical by component group) For Toll Subset",
        },
        {
            bciConfigName: "Wye Subset",
            title: "Bridge Condition Index (Critical by component group) For Wye Subset",
        },
    ],
};

const OrgReportsBciPage: React.FC = () => {
    const { orgShortName } = useParams<{ orgShortName: string }>();

    const getOrgSpecificCharts = useMemo(() => {
        return subsetConfigs[orgShortName]?.map((config) => (
            <BridgeConditionIndexGraphComponent
                key={crypto.randomUUID()}
                chartType="elementGroup"
                bciConfigName={config.bciConfigName}
                scoreType={config.title.includes("Critical") ? "crit" : "avg"}
                title={config.title}
            />
        ));
    }, [orgShortName]);

    return (
        <div>
            <h1>
                BCI Graphs{" "}
                <small style={{ fontSize: "0.5em" }}>
                    (BCI scores are valid only when all elements of the bridge
                    or structure have been inspected and logged in the system)
                </small>
            </h1>
            <ComponentConditionIndexRatiosbyDefectCategory />
            <BridgeConditionIndexGraphComponent
                chartType="elementGroup"
                scoreType="avg"
                title="Bridge Condition Index (Average) by Component Group"
            />
            <BridgeConditionIndexGraphComponent
                chartType="elementGroup"
                scoreType="crit"
                title="Bridge Condition Index (Critical) by Component Group"
            />
            <BridgeConditionIndexGraphComponent
                chartType="defectCategory"
                scoreType="avg"
                title="Bridge Condition Index (Average) by Defect Category"
            />
            <BridgeConditionIndexGraphComponent
                chartType="defectCategory"
                scoreType="crit"
                title="Bridge Condition Index (Critical) by Defect Category"
            />
            {getOrgSpecificCharts}
        </div>
    );
};

export default OrgReportsBciPage;
